import React, {FC} from 'react';
import addDefaultSrc from '../../utils/addDefultImageSrc';
import changeResolution from '../../utils/changeResolution';

type UIImageChooseLinkProps = {
  data: any;
  style: any;
  alt?: any;
  isLarge?: any;
}

const UIImageChooseLink: FC<UIImageChooseLinkProps> = ({data, style, alt, isLarge}) => {
  if (data?.includes('fasad')) {
    if (isLarge) {
      return (
        <img
          // eslint-disable-next-line max-len
          src={`https://process.fasad.eu/rimage.php?url=${data}&i=1&m=strict&c=90`}
          className={`${style}`}
          alt={alt}
          onError={addDefaultSrc}/>
      );
    } else {
      return (
        <img
          // eslint-disable-next-line max-len
          src={`https://process.fasad.eu/rimage.php?url=${changeResolution(data)}&i=1&m=strict&c=90`}
          className={`${style}`}
          alt={alt}
          onError={addDefaultSrc}/>
      );
    }
  } else {
    return (
      <img
        src={data}
        className={`${style}`}
        alt={alt}
        onError={addDefaultSrc}/>
    );
  }
};

export default UIImageChooseLink;
