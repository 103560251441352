/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import LazyLoad from 'react-lazyload';
import {GoPlus} from 'react-icons/go';
import {objectPageApi} from '../api';
import fetchShowings from '../api/fasad/showings';
import addDefaultSrc from '../utils/addDefultImageSrc';
import UILoader from '../components/UICommon/UILoader';
import UIContactForm from '../components/UICommon/UIContactForm';
import UIContactUs from '../components/UICommon/UIContactUs';
import UIImageChooseLink from '../components/UICommon/UIImageChooseLink';
import UIBackToTop from '../components/UICommon/UIBackToTopButton';
import HeroSlices from '../components/Hero/HeroSlices';
import ObjectPageMainText from '../components/ObjectPage/ObjectPageMainText';
import ObjectPageSmallText from '../components/ObjectPage/ObjectPageSmallText';
import FeatureMediaURL from '../components/Feature/FeatureMediaURL';
import AccordionBudgivningPart from '../components/AccordionElements/AccordionBudgivningPart';
import AccordionVisningPart from '../components/AccordionElements/AccordionVisningPart';
import AccordionOmradePart from '../components/AccordionElements/AccordionOmradePart';
import AccordionMaklarePart from '../components/AccordionElements/AccordionMaklarePart';
import AccordionDokumentPart from '../components/AccordionElements/AccordionDokumentPart';
import AccordionHittaHitPart from '../components/AccordionElements/AccordionHittaHitElement';
import AccordionReusablePart from '../components/AccordionElements/AccordionReusablePart';

import image from '../assets/accordion.webp';

const ObjectPage = () => {
  const {id} = useParams();
  const [data, setData] = useState<any>();
  const [isPrice, setIsPrice] = useState<any>();
  const [fasadId, setFasadId] = useState<any>();
  const [showingsData, setShowingsData] = useState<any>();
  const [isFactsActive, setIsFactsActive] = useState(false);
  const [isSecondActive, setIsSecondActive] = useState(false);
  const [isThirdActive, setIsThirdActive] = useState(false);
  const [isFourthActive, setIsFourthActive] = useState(false);
  const [isFifthActive, setIsFifthActive] = useState(false);
  const [isSixActive, setIsSixActive] = useState(false);
  const [isSevenActive, setIsSevenActive] = useState(false);
  const [isEightActive, setIsEightActive] = useState(false);
  const [isNineActive, setIsNineActive] = useState(false);
  const myRef = useRef<any>(null);
  const tempText = 'Vill du veta mer om bostaden kontakta fastighetsmäklare Eric Eiderbrant <a href="mailto:eric@newnest.se" style="text-decoration: underline">eric@newnest.se</a>';
  const contactHeader = data && data[0]?.attributes?.firstFormHeader !== null && data[0]?.attributes?.firstFormHeader.length > 0 ? data[0]?.attributes?.firstFormHeader : 'Vilket boende, jag vill gärna komma på visning!';
  const contactUsHeader = data && data[0]?.attributes?.secondFormHeader !== null && data[0]?.attributes?.secondFormHeader.length > 0 ? data[0]?.attributes?.secondFormHeader : 'Jag vill gärna att ni hittar en liknande bostad åt mig och då baserat på den här bostadens storlek, pris och område!';

  const scrollToShowings = () => {
    setIsThirdActive(true);
    setIsSecondActive(true);
    myRef.current.scrollIntoView();
  };

  useEffect(() => {
    objectPageApi.fetchData(id).then((res) => {
      setFasadId(id);
      setData(res);
    });
  }, []);

  useEffect(() => {
    if (fasadId) {
      fetchShowings(fasadId).then((respose) => {
        setShowingsData(respose.data.data);
      });
    }
  }, [fasadId]);

  useEffect(() => {
    const tempPrice = data && data[0]?.attributes?.facts?.price;
    const tempPriceComment = data && data[0]?.attributes?.facts?.priceComment;
    if (tempPrice !== null && tempPrice !== 'null kr') {
      setIsPrice(tempPrice?.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    } else if (tempPriceComment !== null && tempPriceComment !== 'null kr') {
      setIsPrice(tempPriceComment?.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    } else {
      setIsPrice(null);
    }
  }, [data]);

  const price = isPrice;
  const fee = data && data[0]?.attributes?.facts?.fee;
  const certificateDate = data && data[0]?.attributes?.facts?.certificateDate;
  const plot = data && data[0]?.attributes?.facts?.plot;
  const declarationPerformedBy = data && data[0]?.attributes?.facts?.declarationPerformedBy;
  const energyDeclarationAddress = data && data[0]?.attributes?.facts?.energyDeclarationAddress;
  const electricitySupplier = data && data[0]?.attributes?.facts?.electricitySupplier;
  const energyClass = data && data[0]?.attributes?.facts?.energyClass;
  const feeComment = data && data[0]?.attributes?.facts?.feeComment;
  const elevatorComment = data && data[0]?.attributes?.facts?.elevatorComment;
  const rooms = data && data[0]?.attributes?.numberOfRooms;
  const bedrooms = data && data[0]?.attributes?.bedrooms;
  const bathrooms = data && data[0]?.attributes?.facts?.bathrooms;
  const facade = data && data[0]?.attributes?.facts?.fasad;
  const radon = data && data[0]?.attributes?.facts?.radon;
  const balcony = data && data[0]?.attributes?.facts?.balcony;
  const heating = data && data[0]?.attributes?.facts?.heating;
  const tvInternet = data && data[0]?.attributes?.facts?.tvInternet;
  const taxationCode = data && data[0]?.attributes?.facts?.taxationCode;
  const ventilation = data && data[0]?.attributes?.facts?.energyVentilation;
  const taxationValueBuildings = data && data[0]?.attributes?.facts?.taxationValueBuildings?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const taxationValueTotal = data && data[0]?.attributes?.facts?.taxationValueTotal?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const taxationComment = data && data[0]?.attributes?.facts?.taxationComment;
  const taxationValuePlot = data && data[0]?.attributes?.facts?.taxationValuePlot?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const housingCooperative = data && data[0]?.attributes?.facts?.housingCooperative;
  const shareAmmountOwnership = data && data[0]?.attributes?.facts?.shareAmmountOwnership;
  const shareAmmountOwnershipComment = data && data[0]?.attributes?.facts?.shareAmmountOwnershipComment;
  const knownFeeChanges = data && data[0]?.attributes?.facts?.knownFeeChanges;
  const taxationYear = data && data[0]?.attributes?.facts?.taxationYear;
  const valuationYear = data && data[0]?.attributes?.facts?.valuationYear;
  const fireplace = data && data[0]?.attributes?.facts?.fireplace;
  const generalCondition = data && data[0]?.attributes?.facts?.generalCondition;
  const compassDirection = data && data[0]?.attributes?.facts?.compassDirection;
  const windows = data && data[0]?.attributes?.facts?.windows;
  const foundation = data && data[0]?.attributes?.facts?.foundation;
  const water = data && data[0]?.attributes?.facts?.water;
  const foundationWall = data && data[0]?.attributes?.facts?.foundationWall;
  const masonry = data && data[0]?.attributes?.facts?.masonry;
  const floorMaterial = data && data[0]?.attributes?.facts?.floorMaterial;
  const metalWork = data && data[0]?.attributes?.facts?.metalWork;
  const body = data && data[0]?.attributes?.facts?.body;
  const roof = data && data[0]?.attributes?.facts?.roof;
  const includedInFee = data && data[0]?.attributes?.facts?.includedInFee;
  const excludedInFee = data && data[0]?.attributes?.facts?.excludedInFee;
  const area = data && data[0]?.attributes?.facts?.boarea;
  const comment = data && data[0]?.attributes?.facts?.comment;
  const floor = data && data[0]?.attributes?.facts?.floor;
  const elevator = data && data[0]?.attributes?.facts?.elevator;
  const built = data && data[0]?.attributes?.building?.built;
  const builtComment = data && data[0]?.attributes?.building?.builtComment;
  const builtType = data && data[0]?.attributes?.building?.buildingType;
  const association = data && data[0]?.attributes?.facts?.association;
  const apartmentNumber = data && data[0]?.attributes?.facts?.apartmentNumber;
  const apartmentNumberLmv = data && data[0]?.attributes?.facts?.apartmentNumberLmv;
  const formOfOwnership = data && data[0]?.attributes?.facts?.formOfOwnership;
  const energyStatus = data && data[0]?.attributes?.facts?.energyStatus;
  const energyPerformance = data && data[0]?.attributes?.facts?.energyPerformance;
  const buildingType = data && data[0]?.attributes?.building?.buildingType;
  const heatingType = data && data[0]?.attributes?.building?.heatingType;
  const window = data && data[0]?.attributes?.building?.window;
  const operatingCosts = data && data[0]?.attributes?.operatingCosts;
  const showings = data && data[0]?.attributes?.showings;
  const isShowings = showings && showings.length > 0;
  const realtors = data && data[0]?.attributes?.realtors;
  const documents = data && data[0]?.attributes?.documents;
  let operatingCostsTotal = 0;
  if (operatingCosts && operatingCosts.length > 0) {
    operatingCosts.forEach((element: any) => {
      operatingCostsTotal += parseInt(element.value);
    });
  }
  const bidValue = data && data[0]?.attributes?.bidValue;
  const isBiddings = bidValue && bidValue.length > 0;
  const bidText = data && data[0]?.attributes?.bidText;
  const districtInformation = data && data[0]?.attributes?.districtInformation;
  const lat = data && data[0]?.attributes?.lat;
  const lon = data && data[0]?.attributes?.lon;

  const createDescription = () => {
    const priceCheck = isPrice && isPrice?.length > 0 ? 'Pris ' + isPrice + ' /' : '';
    const areaCheck = area && area?.length > 0 ? ' Boarea ' + area + ' /' : '';
    const roomsCheck = rooms && rooms?.length > 0 ? ' ' + rooms + ' /' : '';
    const feeCheck = fee && fee?.length > 0 ? ' ' + fee + ' /' : '';
    const plotCheck = plot && plot?.length > 0 ? ' Tomt ' + plot : '';
    const decription = priceCheck + areaCheck + roomsCheck + feeCheck + plotCheck;
    const last = decription.charAt(decription.length - 1);

    if (last === '/') {
      const replacedLastOne = decription.slice(0, -1) + '';
      return replacedLastOne;
    }
    return decription;
  };

  const hero = {
    id: '1',
    variant: 'split-right',
    button: {
      buttonText: '',
      buttonLink: '/',
    },
    media: {
      data: {
        attributes: {
          ext: 'string',
          url: data && data[0]?.attributes?.images[0]?.url,
          alternativeText: 'string',
        },
      },
    },
    description: createDescription(),
    header: data && data[0]?.attributes?.salesTitle,
    subheader: data && data[0]?.attributes?.address,
    realtor: realtors ? 'Fastighetsmäklare ' + `${realtors[0].name ? realtors[0].name : ''} ${realtors[0].email ? `<a href="mailto:${realtors[0].email}">${realtors[0].email}</a>` : ''} ${realtors[0].phone ? realtors[0].phone : ''}` : '',
    startFilm: data && data[0]?.attributes?.startFilm,
  };

  const isHideHeader = buildingType !== null || built !== null || heatingType !== null || window !== null;

  const showHeader = (elem: any) => {
    if (elem.value === 'header' && isHideHeader === true) {
      return (
        <div className='font-bold pt-8 pb-2'>
          {elem.name}
        </div>
      );
    } else if (elem.value === 'header' && isHideHeader === false) {
      return null;
    } else {
      return (
        <div>
          {elem.name}
        </div>
      );
    }
  };

  const tmpFacts = [
    {
      name: 'Begärt pris',
      value: price,
    },
    {
      name: 'Avgift',
      value: fee,
    },
    {
      name: 'Avgift kommentar',
      value: feeComment,
    },
    {
      name: 'Ingår i avgiften',
      value: includedInFee,
    },
    {
      name: 'Boarea',
      value: area,
    },
    {
      name: 'Kommentar area',
      value: comment,
    },
    {
      name: 'Tomt',
      value: plot,
    },
    {
      name: 'Rum',
      value: rooms,
    },
    {
      name: 'Badrum',
      value: bathrooms,
    },
    {
      name: 'Ingår ej i avgiften',
      value: excludedInFee,
    },
    {
      name: 'Våningsplan',
      value: floor,
    },
    {
      name: 'Hiss',
      value: elevator === 1 && formOfOwnership === 'Lägenhet - Bostadsrätt' ? 'Ja' : null,
    },
    {
      name: 'Hiss kommentar',
      value: elevatorComment,
    },
    {
      name: 'Byggnadsår',
      value: built,
    },
    {
      name: 'Byggnads kommentar',
      value: builtComment,
    },
    {
      name: 'Byggnadstyp',
      value: builtType,
    },
    {
      name: 'Förening',
      value: association,
    },
    {
      name: 'Lägenhetsnummer',
      value: apartmentNumber,
    },
    {
      name: 'Lägenhetsnummer',
      value: apartmentNumberLmv,
    },
    {
      name: 'Upplåtelseform',
      value: formOfOwnership,
    },
    {
      name: 'Status energideklaration',
      value: energyStatus,
    },
    {
      name: 'Energideklaration registrerad',
      value: certificateDate,
    },
    {
      name: 'Energideklaration ska utföras av',
      value: declarationPerformedBy,
    },
    {
      name: 'Energiprestanda',
      value: energyPerformance !== 0 + ' kWh per kvm och år',
    },
    {
      name: 'Energiklass',
      value: energyClass,
    },
    {
      name: 'Energideklarationsadress',
      value: energyDeclarationAddress,
    },
    {
      name: 'Nuvarande elleverantör',
      value: electricitySupplier,
    },
    {
      name: 'Varav sovrum',
      value: bedrooms,
    },
    {
      name: 'Fasad',
      value: facade,
    },
    {
      name: 'Tak',
      value: roof,
    },
    {
      name: 'Plåtarb',
      value: metalWork,
    },
    {
      name: 'Stomme',
      value: body,
    },
    {
      name: 'Balkong/Terrass',
      value: balcony,
    },
    {
      name: 'Bjälklag',
      value: floorMaterial,
    },
    {
      name: 'Grundläggning',
      value: foundation,
    },
    {
      name: 'Grundmur',
      value: foundationWall,
    },
    {
      name: 'Murstock',
      value: masonry,
    },
    {
      name: 'Vatten och avlopp',
      value: water,
    },
    {
      name: 'Fönster',
      value: windows,
    },
    {
      name: 'Eldstad',
      value: fireplace,
    },
    {
      name: 'Uppvärmning',
      value: heating,
    },
    {
      name: 'Bostadsrättsförening',
      value: housingCooperative,
    },
    {
      name: 'Andelstal ägande',
      value: shareAmmountOwnership,
    },
    {
      name: 'Andelstal ägande kommentar',
      value: shareAmmountOwnershipComment,
    },
    {
      name: 'Känd avgiftsförändring',
      value: knownFeeChanges,
    },
    {
      name: 'Ventilation och Kyla',
      value: ventilation,
    },
    {
      name: 'TV / Data',
      value: tvInternet,
    },
    {
      name: 'Radon',
      value: radon,
    },
    {
      name: 'Allmänt skick',
      value: generalCondition,
    },
    {
      name: 'Huvudsakligt väderstreck',
      value: compassDirection,
    },
    {
      name: 'Värdeår',
      value: valuationYear,
    },
    {
      name: 'Taxeringsår',
      value: taxationYear,
    },
    {
      name: 'Taxeringskod',
      value: taxationCode,
    },
    {
      name: 'Taxeringsvärde byggnad(er)',
      value: taxationValueBuildings,
    },
    {
      name: 'Taxeringsvärde tomt',
      value: taxationValuePlot,
    },
    {
      name: 'Taxeringsvärde totalt',
      value: taxationValueTotal,
    },
    {
      name: 'Kommentar till taxeringen',
      value: taxationComment,
    },
    {
      name: 'Driftkostnad (per månad)',
      value: 'object',
    },
    {
      name: 'Byggnadssätt',
      value: 'header',
    },
    {
      name: 'Byggnadstyp',
      value: buildingType,
    },
    {
      name: 'Byggår',
      value: built,
    },
    {
      name: 'Uppvärmning',
      value: heatingType,
    },
    {
      name: 'Fönster',
      value: window,
    },
  ];

  let segments = [];
  let videoId = '';
  if (data && data[0]?.attributes?.beskrivningFilm != null && data[0]?.attributes?.beskrivningFilm?.length > 0) {
    segments = data[0]?.attributes?.beskrivningFilm.split('/');
    videoId = segments[segments.length - 1];
  }

  let segments2 = [];
  let videoId2 = '';
  if (data && data[0]?.attributes?.vimeoHero != null && data[0]?.attributes?.vimeoHero?.length > 0) {
    segments2 = data[0]?.attributes?.vimeoHero.split('/');
    videoId2 = segments2[segments2.length - 1];
  }

  return (
    <div className='mb-5 md:px-10'>
      {data ? (
        <>
          <HeroSlices item={hero} isBold={true} page="objekt" isShowings={isShowings} isBiddings={isBiddings} scrollToShowings={scrollToShowings} />
          {data[0]?.attributes?.images[1]?.url && (
            <LazyLoad once className='w-full max-h-840 overflow-hidden flex justify-center items-center my-5'>
              {videoId2 !== '' ? (
                <iframe
                  title={'title'}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId2}?h=b45fcf7929&amp;badge=0&loop=1&autoplay=1&muted=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                  height="100%"
                  width="100%"
                  frameBorder="0"
                  className={`w-full h-screen object-cover xl:mb-[10vw] xl:mt-[0px] lg:my-[-10vh] md:my-[-15vh] sm:my-[-25vh]  my-[-35vh] overflow-x-hidden`}
                  allowFullScreen></iframe>
              ) : (
                <Link
                  to={`/objekt/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[1]?.url}
                    style="w-full h-full object-cover" />
                </Link>
              )}

            </LazyLoad>
          )}
          {(data[0]?.attributes?.salesTextShort || data[0]?.attributes?.salesText) && (
            <ObjectPageMainText title={data[0]?.attributes?.salesTextShort} description={data[0]?.attributes?.salesText} />
          )}
          {(data[0]?.attributes?.images[2]?.url && data[0]?.attributes?.images[3]?.url) && (
            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 my-0 md:my-6 overflow-hidden'>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/objekt/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[2]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0" />
                </Link>
              </LazyLoad>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/objekt/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[3]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0" />
                </Link>
              </LazyLoad>
            </div>
          )}
          {data[0]?.attributes?.images.length > 2 && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.2,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} >
              <Link
                to={`/objekt/${id}/gallery`}
                className='flex flex-col justify-center items-center font-bold py-5 cursor-pointer'>
                <GoPlus className="h-4 w-4 mb-0.5 font-bold text-7xl" />
                <div>alla bilder</div>
              </Link>
            </motion.div>
          )}
          {(data[0]?.attributes?.blueprint !== null && data[0]?.attributes?.blueprint.length > 0) && (
            <div className={
              'bg-gray-feature relative py-6 w-full lg:h-840 overflow-hidden flex justify-center items-center my-5 ' +
              ' h-490'}>
              <FeatureMediaURL images={data[0]?.attributes?.blueprint} isBlueprint={true} />
            </div>
          )}
          <div className='flex flex-col md:flex-row md:space-x-10'>
            <LazyLoad once className={
              'w-full md:w-1/2 max-h-730 h-[500px] md:h-730 relative'}>
              {videoId !== '' ? (
                <div className="w-full h-full overflow-x-hidden vimeo-video-wrapper-object relative">
                  <iframe
                    title={'title'}
                    // eslint-disable-next-line max-len
                    src={`https://player.vimeo.com/video/${videoId}?h=b45fcf7929&amp;badge=0&loop=1&autoplay=1&muted=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    height="100%"
                    frameBorder="0"
                    className={`w-full h-full object-cover`}
                    allowFullScreen></iframe>
                </div>
              ) : (
                <img
                  src={image}
                  className="w-full h-full object-cover object-top"
                  onError={addDefaultSrc} />
              )}
            </LazyLoad>
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.4,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} className='w-full md:w-1/2 flex justify-center flex-col text-3xl font-bold pt-6 px-8'>
              {(data[0]?.attributes?.beskrivning !== null && data[0]?.attributes?.beskrivning.length > 0) && (
                <AccordionReusablePart
                  title="Beskrivning"
                  isElementActive={isSevenActive}
                  setIsElementActive={setIsSevenActive}
                  element={data[0]?.attributes?.beskrivning}
                />
              )}
              <div className="accordion-item" ref={myRef}>
                <div className="flex items-center cursor-pointer my-1 md:my-2" onClick={() => setIsFactsActive(!isFactsActive)}>
                  <div className='text-xl md:text-3xl mr-4'>{isFactsActive ? '-' : '+'}</div>
                  <div className='text-xl md:text-3xl'>Fakta</div>
                </div>
                <div className={
                  `${isFactsActive ? 'h-auto mb-4 md:mb-10 transition-all duration-300 ' :
                    'h-0 transition-all overflow-hidden opacity-0 duration-300 '}` +
                  'md:flex justify-between w-full'
                }>
                  <div className='text-sm font-normal pl-7 w-full'>
                    {tmpFacts.map((el, idx) => (
                      <motion.div
                        key={idx}
                        initial={{opacity: 0}}
                        whileInView={{opacity: [0, 0.3, 0.8, 1]}}
                        viewport={{once: true}}
                        transition={{
                          delay: 0.1 * idx,
                          x: {type: 'easeOut', stiffness: 100},
                          default: {duration: 1},
                        }} >
                        {(el.value !== null && el.value !== undefined && el.value.length > 0 && el.value !== 'object') && (
                          <div className="flex justify-between w-full">
                            {showHeader(el)}
                            {el.value !== 'header' && (
                              <>
                                <div className='flex flex-grow border-b border-black border-dotted mx-3 mb-1'></div>
                                <div>{el.value.replaceAll('null', '-')}</div>
                              </>
                            )}
                          </div>
                        )}
                        {(operatingCostsTotal > 0) && (
                          <div>
                            <div className='font-bold pt-8 pb-2'>Driftkostnad (per månad)</div>
                            {operatingCosts.map((item: any, idx: any) => (
                              <div className="flex justify-between w-full" key={idx}>
                                <div>
                                  {item.key}
                                </div>
                                <div className='flex flex-grow border-b border-black border-dotted mx-3 mb-1'></div>
                                <div>{item.value} kr</div>
                              </div>
                            ))}
                            <div className="flex justify-between w-full" key={idx}>
                              <div>
                                Summa
                              </div>
                              <div className='flex flex-grow border-b border-black border-dotted mx-3 mb-1'></div>
                              <div>{operatingCostsTotal} kr</div>
                            </div>
                          </div>
                        )}
                      </motion.div>
                    ),
                    )}
                  </div>
                </div>
              </div>
              {bidValue !== null && bidText !== null && (
                <AccordionBudgivningPart
                  setIsSecondActive={setIsSecondActive}
                  isSecondActive={isSecondActive}
                  bidValue={bidValue}
                  bidText={bidText}
                  realtors={realtors}
                />
              )}
              {showings.length > 0 && (
                <AccordionVisningPart
                  setIsThirdActive={setIsThirdActive}
                  isThirdActive={isThirdActive}
                  showings={showings}
                />
              )}
              {(data[0]?.attributes?.forenign !== null && data[0]?.attributes?.forenign.length > 0) && (
                <AccordionReusablePart
                  title="Förening"
                  isElementActive={isEightActive}
                  setIsElementActive={setIsEightActive}
                  element={data[0]?.attributes?.forenign}
                />
              )}
              {districtInformation && (
                <AccordionOmradePart
                  setIsFourthActive={setIsFourthActive}
                  isFourthActive={isFourthActive}
                  districtInformation={districtInformation}
                />
              )}
              {(data[0]?.attributes?.fastighet !== null && data[0]?.attributes?.fastighet.length > 0) && (
                <AccordionReusablePart
                  title="Fastighet"
                  isElementActive={isNineActive}
                  setIsElementActive={setIsNineActive}
                  element={data[0]?.attributes?.fastighet}
                />
              )}
              {(realtors && realtors.length > 0) && (
                <AccordionMaklarePart
                  setIsFifthActive={setIsFifthActive}
                  isFifthActive={isFifthActive}
                  realtors={realtors}
                />
              )}
              {(documents && documents.length > 0) && (
                <AccordionDokumentPart
                  setIsSixActive={setIsSixActive}
                  isSixActive={isSixActive}
                  documents={documents}
                />
              )}
              {(lat !== null && lon !== null) && (
                <AccordionHittaHitPart
                  lat={lat}
                  lon={lon}
                />
              )}
            </motion.div>
          </div>
          {data[0]?.attributes?.images[4]?.url && (
            <LazyLoad once className='w-full max-h-840 overflow-hidden flex justify-center items-center my-5'>
              <Link
                to={`/objekt/${id}/gallery`}
                className="w-full h-full">
                <UIImageChooseLink
                  data={data[0]?.attributes?.images[4]?.url}
                  style="w-full h-full object-cover" />
              </Link>
            </LazyLoad>
          )}
          <ObjectPageSmallText text={tempText} />
          <div id="contact"></div>
          <UIContactForm
            address={data[0]?.attributes?.address}
            fasadId={data[0]?.attributes?.fasadID}
            color="bg-beige-contact"
            header={contactHeader}
            showingsData={showingsData}
          />
          <UIContactUs fasadId={data[0]?.attributes?.fasadID} color="bg-beige-contact" header={contactUsHeader} />
          <motion.div
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.4,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }}>
            <UIBackToTop />
          </motion.div>
        </>
      ) : (
        <UILoader />
      )}
    </div>
  );
};

export default ObjectPage;
