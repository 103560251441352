/* eslint-disable */
import client from './client';
import {ObjectPage} from '../types';

const qs = require('qs');

async function fetchObjectPage(id: any) : Promise<ObjectPage> {
  const query = qs.stringify({
    filters: {
      fasadID: {
        $eq: id,
      },
    },
    populate: {
      populate: ['salesTitle', 'salesTextShort', 'salesText', 'startFilm', 'beskrivningFilm'],
      images: {
        populate: ['*'],
      },
      realtors: {
        populate: ['*'],
      },
      documents: {
        populate: ['*'],
      },
      blueprint: {
        populate: ['*'],
      },
      facts: {
        populate: ['*'],
      },
      operatingCosts: {
        populate: ['*'],
      },
      building: {
        populate: ['*'],
      },
      showings: {
        populate: ['*'],
      },
      beskrivning: {
        populate: ['*'],
      },
      forenign: {
        populate: ['*'],
      },
      fastighet: {
        populate: ['*'],
      },
    }, 
  }, {
    encodeValuesOnly: true,
  });
  const res = await client.get(`objekts?${query}`);
  return res.data.data;
}

function fetchData(id: any) {
  return fetchObjectPage(id);
}

export default {
  fetchData,
};
