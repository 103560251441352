/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import LazyLoad from 'react-lazyload';
import {newProductionPageApi} from '../api';
import UILoader from '../components/UICommon/UILoader';
import UIBackToTop from '../components/UICommon/UIBackToTopButton';
import UIGoToGalleryButton from '../components/UICommon/UIGoToGalleryButton';
import UIContactForm from '../components/UICommon/UIContactForm';
import UIContactUs from '../components/UICommon/UIContactUs';
import UIImageChooseLink from '../components/UICommon/UIImageChooseLink';
import NewProductionPageTable from '../components/NewProductionPage/NewProductionPageTable';
import HeroSlices from '../components/Hero/HeroSlices';
import ObjectPageMainText from '../components/ObjectPage/ObjectPageMainText';
import ObjectPageSmallText from '../components/ObjectPage/ObjectPageSmallText';
import NewProductionPageProgressBar from '../components/NewProductionPage/NewProductionPageProgressBar';
import FeatureMediaURL from '../components/Feature/FeatureMediaURL';

const NewProductionPage = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [data, setData] = useState<any>();
  const [isPrice, setIsPrice] = useState<any>();

  useEffect(() => {
    newProductionPageApi.fetchData(id).then((res) => {
      setData(res);
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const tempPrice = data && data[0]?.attributes?.price;
    if (tempPrice !== null && tempPrice !== 'null kr') {
      setIsPrice(tempPrice?.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    } else {
      setIsPrice('-');
    }
  }, [data]);

  const area = data && data[0]?.attributes?.size;
  const price = isPrice;
  const objectsForTable = data && data[0]?.attributes?.objekts?.data;
  const realtors = data && data[0]?.attributes?.realtors;
  const hero = {
    id: '1',
    variant: 'split-right',
    button: {
      buttonText: '',
      buttonLink: '/',
    },
    media: {
      data: {
        attributes: {
          ext: 'string',
          url: data && data[0]?.attributes?.images[0]?.url,
          alternativeText: 'string',
        },
      },
    },
    description: data && data[0]?.attributes?.kortFakta !== null && data[0]?.attributes?.kortFakta?.length > 0 ? data[0]?.attributes?.kortFakta : 'Pris ' + price?.replaceAll('null', '-') + ' / ' + area,
    header: data && data[0]?.attributes?.salesTitle,
    subheader: data && data[0]?.attributes?.projectName,
    realtor: realtors ? 'Fastighetsmäklare ' +`${realtors[0].name ? realtors[0].name : ''} ${realtors[0].email ? realtors[0].email : ''} ${realtors[0].phone ? realtors[0].phone : ''}` : '',
    startFilm: data && data[0]?.attributes?.startFilm,
  };

  const tempText = `Vill du veta mer om projektet kontakta fastighetsmäklare Eric Eiderbrant <a href="mailto:eric@newnest.se" style="text-decoration: underline">eric@newnest.se</a>`;
  const contactHeader = data && data[0]?.attributes?.firstFormHeader !== null && data[0]?.attributes?.firstFormHeader.length > 0 ? data[0]?.attributes?.firstFormHeader : 'Vilket projekt, jag är såklart interesserad!';
  const contactUsHeader = data && data[0]?.attributes?.secondFormHeader !== null && data[0]?.attributes?.secondFormHeader.length > 0 ? data[0]?.attributes?.secondFormHeader : 'Hej, jag vill gärna veta när ni får ut fler nyproduktionsprojekt!';

  const handleClick = (id: number) => {
    navigate(`/objekt/${id}`);
  };

  const putZoomSection = () => {
    if (data[0]?.attributes?.iframe !== null && data[0]?.attributes?.iframe.length > 0) {
      return (
        <div className={
          'bg-gray-feature relative py-6 w-full overflow-hidden flex justify-center items-center my-5 ' +
          ' h-auto'}>
          <div
            dangerouslySetInnerHTML={{__html: data[0]?.attributes?.iframe}}
            className="w-full h-full object-cover px-6"></div>
        </div>
      );
    } else if (data[0]?.attributes?.blueprint !== null && data[0]?.attributes?.blueprint.length > 0) {
      return (
        <div className={
          'bg-gray-feature relative py-6 w-full lg:h-840 overflow-hidden flex justify-center items-center my-5 ' +
          ' h-490'}>
          <FeatureMediaURL images={data[0]?.attributes?.blueprint} isBlueprint={true}/>
        </div>
      );
    } else {
      return null;
    }
  };

  let segments2 = [];
  let videoId2 = '';
  if (data && data[0]?.attributes?.vimeoHero != null && data[0]?.attributes?.vimeoHero?.length > 0) {
    segments2 = data[0]?.attributes?.vimeoHero.split('/');
    videoId2 = segments2[segments2.length - 1];
  }

  return (
    <div className='mb-5 md:px-10'>
      {data ? (
        <>
          <HeroSlices item={hero} isBold={true} page="nyproduktion"/>
          {data[0]?.attributes?.images[1]?.url && (
            <LazyLoad once className='w-full max-h-840 overflow-hidden flex justify-center items-center my-5'>
              {videoId2 !== '' ? (
                <iframe
                  title={'title'}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId2}?h=b45fcf7929&amp;badge=0&loop=1&autoplay=1&muted=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                  height="100%"
                  width="100%"
                  frameBorder="0"
                  className={`w-full h-screen object-cover xl:mb-[10vw] xl:mt-[0px] lg:my-[-10vh] md:my-[-15vh] sm:my-[-25vh]  my-[-35vh] overflow-x-hidden`}
                  allowFullScreen></iframe>
              ) : (
                <Link
                  to={`/nyproduktion/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[1]?.url}
                    style="w-full h-full object-cover"/>
                </Link>
              )}
            </LazyLoad>
          )}
          {(data[0]?.attributes?.salesTextShort || data[0]?.attributes?.salesText) && (
            <ObjectPageMainText
              title={data[0]?.attributes?.salesTextShort}
              description={data[0]?.attributes?.salesText}/>
          )}
          {data[0]?.attributes?.nyproduktionStatus !== null && (
            <NewProductionPageProgressBar status={data[0]?.attributes?.nyproduktionStatus}/>
          )}
          {(data[0]?.attributes?.images[2]?.url && data[0]?.attributes?.images[3]?.url) && (
            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 my-0 md:my-6 overflow-hidden'>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/nyproduktion/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[2]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0"/>
                </Link>
              </LazyLoad>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/nyproduktion/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[3]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0"/>
                </Link>
              </LazyLoad>
            </div>
          )}
          {data[0]?.attributes?.images.length > 2 && (<UIGoToGalleryButton id={id}/>)}
          {putZoomSection()}
          {objectsForTable.length > 0 && (
            <NewProductionPageTable objectsForTable={objectsForTable} handleClick={handleClick}/>
          )}
          {(data[0]?.attributes?.externalLink !== null && data[0]?.attributes?.externalLink.length > 0) && (
            <div className='flex justify-center items-center w-full'>
              <a
                target="_blank"
                rel="noreferrer"
                href={data[0]?.attributes?.externalLink}
                className='text-black py-1 px-10 border border-black text-15 text-center my-8'
              >Läs mer</a>
            </div>
          )}
          {data[0]?.attributes?.images[5]?.url && (
            <LazyLoad once className={
              'w-full max-h-840 overflow-hidden flex justify-center items-center my-5'}>
              <Link
                to={`/nyproduktion/${id}/gallery`}
                className="w-full h-full">
                <UIImageChooseLink
                  data={data[0]?.attributes?.images[5]?.url}
                  style="w-full h-full object-cover"/>
              </Link>
            </LazyLoad>
          )}
          <ObjectPageSmallText text={tempText} />
          <UIContactForm
            address={data[0]?.attributes?.projectName}
            fasadId={data[0]?.attributes?.fasadID}
            color="bg-gray-feature"
            header={contactHeader}
          />
          <UIContactUs
            fasadId={data[0]?.attributes?.fasadID}
            color="bg-gray-feature"
            header={contactUsHeader}
          />
          <motion.div
            onClick={scrollToTop}
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.4,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }}>
            <UIBackToTop />
          </motion.div>
        </>
      ) : (
        <UILoader />
      )}
    </div>
  );
};

export default NewProductionPage;
