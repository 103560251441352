/* eslint-disable max-len */
import React, {useState} from 'react';
import {FiSearch} from 'react-icons/fi';
import {
  objectListApi,
  newProductionListApi,
  propertyDividerApi,
} from '../api';
import FeatureSlices from '../components/Feature/FeatureSlices';
import NewNestPageSearchResult from '../components/NewNestPage/NewNestPageSearchResult';
import UIBackToTop from '../components/UICommon/UIBackToTopButton';

const objectList = objectListApi.fetchData();
const newProductionList = newProductionListApi.fetchData();
const propertyDividers = propertyDividerApi.fetchData();

const NewNestPage = () => {
  const objectData = objectList.content.read();
  const newProductionData = newProductionList.content.read();
  const dividerData = propertyDividers.content.read();
  const [queryNew, setQueryNew] = useState('');
  const [queryNest, setQueryNest] = useState('');
  const displayedObjects = [] as any[];
  const connectedLists = newProductionData.concat(objectData);

  const createDescription = (objekt: any) => {
    const decription =
      (objekt?.facts?.price ? objekt?.facts?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' / ' : '') +
      (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea +' / ' : '') +
      (objekt?.numberOfRooms ? objekt?.numberOfRooms + ' / ' : '') +
      (objekt?.facts?.fee ? objekt?.facts?.fee + ' /' : '') +
      (objekt?.facts?.plot ? ' Tomt ' + objekt?.facts?.plot : '');
    const last = decription.charAt(decription.length - 1);

    if (last === '/') {
      const replacedLastOne = decription.slice(0, -1) + '';
      return replacedLastOne;
    }
    return decription;
  };

  return (
    <div>
      <div className='max-w-screen-2xl mx-auto mt-8'>
        <div className='flex flex-col px-2 md:pl-5 lg:pl-10 mx-auto pt-4 w-full md:w-1/2 ml-0'>
          <div className='border-b border-black w-full relative flex'>
            <input
              className='text-black text-xl md:text-2xl lg:text-35 w-full placeholder-black'
              placeholder="new / nyproduktion"
              onChange={(event) => setQueryNew(event.target.value)}
            />
            <FiSearch className='w-5 h-5 md:w-7 md:h-7 lg:w-8 lg:h-8 absolute right-1 bottom-1'/>
          </div>
          <div id="Results_new" className='w-full'>
            {connectedLists
                .filter((item: any) => {
                  if (queryNew === '') {
                    return null;
                  } else if (
                    item?.attributes?.municipality
                        .toLowerCase()
                        .includes(queryNew.toLowerCase())
                  ) {
                    return item;
                  }
                })
                ?.map((item: any) => {
                  const title = 'new ' + item?.attributes?.municipality;
                  const secondText = item?.attributes?.address;
                  const description = item?.attributes?.salesTitle;
                  const subHeader = item?.attributes?.kortFakta ? item?.attributes?.kortFakta : item?.attributes?.size +
                  ' / ' +
                  item?.attributes?.numberOfRooms +
                  ' / ' +
                  item?.attributes?.fee;

                  if (item?.attributes?.status === 'for sale' && item?.attributes?.nyproduktionStatus) {
                    return (
                      <NewNestPageSearchResult
                        key={`nest-sale-${item?.attributes.fasadID}`}
                        title={title}
                        secondText={secondText}
                        description={description}
                        subHeader={subHeader}
                        link="nyproduktion"
                        id={item.id}
                      />
                    );
                  } else if (item?.attributes?.status === 'for sale' && item?.attributes?.isNewConstruction) {
                    return (
                      <NewNestPageSearchResult
                        key={`construction-sale-${item?.attributes.fasadID}`}
                        title={title}
                        secondText={secondText}
                        description={description}
                        subHeader={subHeader}
                        link="objekt"
                        id={item.attributes.fasadID}
                      />
                    );
                  }
                  if (item?.attributes?.status === 'upcoming') {
                    return (
                      <NewNestPageSearchResult
                        key={`nest-upcoming-${item?.attributes.fasadID}`}
                        title={title}
                        secondText={secondText}
                        description={description}
                        subHeader={subHeader}
                        link="kontakt"
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
          </div>
        </div>
        <div className='flex flex-col px-2 md:pl-5 lg:pl-10 max-w-screen-2xl mx-auto py-4 w-full md:w-1/2 ml-0'>
          <div className='border-b border-black w-full relative flex'>
            <input
              className='text-black text-xl md:text-2xl lg:text-35 w-full placeholder-black'
              placeholder="nest / våning / villa / landställe"
              onChange={(event) => setQueryNest(event.target.value)}
            />
            <FiSearch className='w-5 h-5 md:w-7 md:h-7 lg:w-8 lg:h-8 absolute right-1 bottom-1'/>
          </div>
          <div id="Results_nest" className='w-full'>
            {objectData
                .filter((item: any) => {
                  if (queryNest === '') {
                    return null;
                  } else if (
                    item?.attributes?.municipality
                        .toLowerCase()
                        .includes(queryNest.toLowerCase()) ||
                    item?.attributes?.address
                        .toLowerCase()
                        .includes(queryNest.toLowerCase())
                  ) {
                    return item;
                  }
                })
                ?.map((item: any) => {
                  const title = 'nest ' + item?.attributes?.municipality;
                  const secondText = item?.attributes?.address;
                  const description = item?.attributes?.salesTitle;

                  // let objects = item?.attributes?.objekts?.data;
                  // let numberOfObjects = objects.length;
                  const subHeader =
                  item?.attributes?.size ? (item?.attributes?.size +' / ') : '' +
                    item?.attributes?.numberOfRooms ? (item?.attributes?.numberOfRooms + ' / ') : '' +
                    item?.attributes?.fee ? item?.attributes?.fee : '';

                  if (item?.attributes?.status === 'for sale' && item?.attributes?.isNewConstruction === false) {
                    return (
                      <NewNestPageSearchResult
                        key={`object-sale-${item?.attributes.fasadID}`}
                        title={title}
                        secondText={secondText}
                        description={description}
                        subHeader={subHeader}
                        link="objekt"
                        id={item.attributes.fasadID}
                      />
                    );
                  }
                  if (item?.attributes?.status === 'upcoming') {
                    return (
                      <NewNestPageSearchResult
                        key={`object-upcoming-${item?.attributes.fasadID}`}
                        title={title}
                        secondText={secondText}
                        description={description}
                        subHeader={subHeader}
                        link="kontakt"
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
          </div>
        </div>
      </div>

      {newProductionData?.map((item: any) => {
        const municipality = item?.attributes?.municipality;
        const objects = item?.attributes?.objekts?.data;
        const numberOfObjects = objects.length;
        if (item?.attributes?.showInList === false) {
          return null;
        }
        if (item?.attributes?.status === 'for sale') {
          return (
            <div key={item.id}>
              <FeatureSlices
                key={item.id}
                item={{
                  id: 5 + item.id,
                  variant: 'split-right',
                  color: 'beige',
                  title: {
                    variant: 'black',
                    textFirstPart: 'new ' + municipality,
                    textSecondPart: item?.attributes?.projectName,
                  },
                  subheader:
                    item?.attributes?.kortFakta !== null && item?.attributes?.kortFakta?.length > 0 ?
                    item?.attributes?.kortFakta :
                    numberOfObjects +
                    ' Objekt / ' +
                    item?.attributes?.size +
                    ' / ' +
                    item?.attributes?.numberOfRooms +
                    ' / ' +
                    item?.attributes?.fee,
                  images: item?.attributes.images,
                  media: item?.attributes.media,
                  description: item?.attributes?.salesTitle,
                  buttons: [
                    {
                      buttonLink: `/nyproduktion/${item.attributes.fasadID}`,
                      buttonText: 'Läs mer',
                    },
                  ],
                  objekt: '',
                }}
              />
              {item.attributes.objekts && item.attributes.objekts.data.length > 0 &&
                item.attributes.objekts.data.map((item : any) => {
                  displayedObjects.push(item.id);
                  let readMoreText = 'Läs mer';
                  const objekt = item?.attributes;
                  const newNest = item?.attributes?.isNewConstruction ? 'new ' : 'nest ';
                  if (item?.attributes?.bidValue) {
                    readMoreText = 'Budgivning pågår - Läs mer';
                  }
                  if (item?.attributes?.bidValue && item?.attributes.showings.length > 0) {
                    readMoreText = 'Budgivning & Visning - Läs mer';
                  }
                  if (item?.attributes?.status === 'for sale') {
                    return (
                      <FeatureSlices
                        key={item.id}
                        item={{
                          id: 5 + item.id,
                          variant: 'split-left',
                          color: 'gray',
                          title: {
                            variant: 'beige-black',
                            textFirstPart: newNest + municipality,
                            textSecondPart: item?.attributes?.address,
                          },
                          subheader:
                            (objekt?.facts?.price ? objekt?.facts?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' / ' : '') +
                            (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea +' / ' : '') +
                            (objekt?.numberOfRooms ? objekt?.numberOfRooms + ' / ' : '') +
                            (objekt?.facts?.fee ? objekt?.facts?.fee + ' / ' : '') +
                            (objekt?.facts?.plot ? 'Tomt ' + objekt?.facts?.plot : ''),
                          images: item?.attributes.images,
                          media: item?.attributes.media,
                          description: item?.attributes?.salesTitle,
                          buttons: [
                            {
                              buttonLink: `/objekt/${item.attributes.fasadID}`,
                              buttonText: readMoreText,
                            },
                          ],
                          objekt: '',
                        }}
                      />
                    );
                  }
                  if (item?.attributes?.status === 'upcoming') {
                    return (
                      <FeatureSlices
                        key={item.id}
                        item={{
                          id: 11 + item.id,
                          variant: 'split-left',
                          color: 'gray',
                          title: {
                            variant: 'beige-black',
                            textFirstPart: newNest + municipality,
                            textSecondPart: 'Kommande',
                          },
                          subheader:
                            (objekt?.facts?.price ? objekt?.facts?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' / ' : '') +
                            (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea +' / ' : '') +
                            (objekt?.numberOfRooms ? objekt?.numberOfRooms + ' / ' : '') +
                            (objekt?.facts?.fee ? objekt?.facts?.fee + ' / ' : '') +
                            (objekt?.facts?.plot ? 'Tomt ' + objekt?.facts?.plot : ''),
                          images: item?.attributes.images,
                          media: item?.attributes.media,
                          description: item?.attributes?.salesTitle,
                          buttons: [
                            {
                              buttonLink: `/kontakt`,
                              buttonText:
                      'Kontakta fastighetsmäklaren för mer information',
                            },
                          ],
                          objekt: '',
                        }}
                      />
                    );
                  } else {
                    return;
                    <></>;
                  }
                })
              }
            </div>
          );
        }
        if (item?.attributes?.status === 'upcoming') {
          const objekt = item?.attributes;
          return (
            <FeatureSlices
              key={item.id}
              item={{
                id: 11 + item.id,
                variant: 'split-left',
                color: 'beige',
                title: {
                  variant: 'black',
                  textFirstPart: 'new ' + municipality,
                  textSecondPart: item?.attributes?.address,
                },
                subheader:
                  (objekt?.facts?.price ? objekt?.facts?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' / ' : '') +
                  (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea +' / ' : '') +
                  (objekt?.numberOfRooms ? objekt?.numberOfRooms + ' / ' : '') +
                  (objekt?.facts?.fee ? objekt?.facts?.fee + ' / ' : '') +
                  (objekt?.facts?.plot ? 'Tomt ' + objekt?.facts?.plot : ''),
                images: item?.attributes.images,
                media: item?.attributes.media,
                description: item?.attributes?.salesTitle,
                buttons: [
                  {
                    buttonLink: `/kontakt`,
                    buttonText:
                      'Kontakta fastighetsmäklaren för mer information',
                  },
                ],
                objekt: '',
              }}
            />
          );
        } else {
          return;
          <></>;
        }
      })}
      <FeatureSlices key={0} item={dividerData?.attributes?.firstFeature} />
      {objectData?.map((item: any) => {
        const municipality = item?.attributes?.municipality;
        let readMoreText = 'Läs mer';
        const newNest = item?.attributes?.isNewConstruction ? 'new ' : 'nest ';
        if (displayedObjects.includes(item.id)) {
          return null;
        }
        if (item?.attributes?.bidValue) {
          readMoreText = 'Budgivning pågår - Läs mer';
        }
        if (item?.attributes.showings.length > 0) {
          readMoreText = 'Boka visning - Läs mer';
        }
        if (item?.attributes?.bidValue && item?.attributes.showings.length > 0) {
          readMoreText = 'Budgivning & Visning - Läs mer';
        }
        if (item?.attributes?.showInList === false) {
          return null;
        }
        if (item?.attributes?.status === 'for sale' && !item?.attributes?.NewConstruction) {
          const objekt = item?.attributes;
          return (
            <FeatureSlices
              key={item.id}
              item={{
                id: 5 + item.id,
                variant: 'split-left',
                color: 'gray',
                title: {
                  variant: 'beige-black',
                  textFirstPart: newNest + municipality,
                  textSecondPart: item?.attributes?.address,
                },
                subheader:
                createDescription(objekt),
                images: item?.attributes.images,
                media: item?.attributes.media,
                description: item?.attributes?.salesTitle,
                buttons: [
                  {
                    buttonLink: `/objekt/${item.attributes.fasadID}`,
                    buttonText: readMoreText,
                  },
                ],
                objekt: '',
              }}
            />
          );
        }
        if (item?.attributes?.status === 'upcoming') {
          const objekt = item?.attributes;
          return (
            <FeatureSlices
              key={item.id}
              item={{
                id: 11 + item.id,
                variant: 'split-left',
                color: 'gray',
                title: {
                  variant: 'beige-black',
                  textFirstPart: newNest + municipality,
                  textSecondPart: 'Kommande',
                },
                subheader:
                  (objekt?.facts?.price ? objekt?.facts?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' / ' : '') +
                  (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea +' / ' : '') +
                  (objekt?.numberOfRooms ? objekt?.numberOfRooms + ' / ' : '') +
                  (objekt?.facts?.fee ? objekt?.facts?.fee + ' / ' : '') +
                  (objekt?.facts?.plot ? 'Tomt ' + objekt?.facts?.plot : ''),
                images: item?.attributes.images,
                media: item?.attributes.media,
                description: item?.attributes?.salesTitle,
                buttons: [
                  {
                    buttonLink: `/kontakt`,
                    buttonText:
                      'Kontakta fastighetsmäklaren för mer information',
                  },
                ],
                objekt: '',
              }}
            />
          );
        } else {
          return;
          <></>;
        }
      })}
      <FeatureSlices key={1} item={dividerData?.attributes?.secondFeature} />
      {objectData?.map((item: any) => {
        const municipality = item?.attributes?.municipality;
        if (item?.attributes?.showInList === false) {
          return null;
        }
        if (item?.attributes?.status === 'sold') {
          const objekt = item?.attributes;
          return (
            <FeatureSlices
              key={item.id}
              item={{
                id: 11 + item.id,
                variant: 'split-left',
                color: 'gray',
                title: {
                  variant: 'black-beige',
                  textFirstPart: 'såld ',
                  textSecondPart: municipality + ' ' + item?.attributes?.address,
                },
                subheader:
                  (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea : '') +
                  (objekt?.numberOfRooms && objekt?.facts?.boarea.length > 0 ? ' / ' + objekt?.numberOfRooms : '') +
                  (objekt?.numberOfRooms && objekt?.facts?.boarea.length < 0 ? objekt?.numberOfRooms : '') +
                  (objekt?.facts?.fee && objekt?.numberOfRooms.length > 0 || objekt?.numberOfRooms.length > 0 ? ' / ' + objekt?.facts?.fee : '') +
                  (objekt?.facts?.fee && objekt?.numberOfRooms.length < 0 && objekt?.numberOfRooms.length < 0 ? objekt?.facts?.fee : '') +
                  (objekt?.facts?.plot && objekt?.facts?.plot.length > 0 && objekt?.facts?.fee.length > 0 && objekt?.numberOfRooms.length > 0 && objekt?.numberOfRooms.length > 0 ? ' / ' + 'Tomt ' + objekt?.facts?.plot : '') +
                  (objekt?.facts?.plot && objekt?.facts?.fee.length < 0 && objekt?.numberOfRooms.length < 0 && objekt?.numberOfRooms.length < 0 ? 'Tomt ' + objekt?.facts?.plot : ''),
                images: item?.attributes.images,
                media: item?.attributes.media,
                description: item?.attributes?.salesTitle,
                buttons: [
                  {
                    buttonLink: `/kontakt`,
                    buttonText:
                      'Kontakta fastighetsmäklaren för mer information',
                  },
                ],
                objekt: '',
              }}
            />
          );
        } else {
          return;
          <></>;
        }
      })}
      <UIBackToTop />
    </div>
  );
};

export default NewNestPage;
