import axios from 'axios';

const client = axios.create({
  baseURL: 'https://api.fasad.eu/v2/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export default client;
