import React, {FC} from 'react';
import {motion} from 'framer-motion';

type AccordionMaklarePartProps = {
  setIsFifthActive: any;
  isFifthActive: any;
  realtors: any;
}

const AccordionMaklarePart: FC<AccordionMaklarePartProps> = ({
  setIsFifthActive,
  isFifthActive,
  realtors,
}) => {
  return (
    <div className="accordion-item">
      <div className="flex items-center cursor-pointer my-1 md:my-2" onClick={() => setIsFifthActive(!isFifthActive)}>
        <div className='text-xl md:text-3xl mr-4'>{isFifthActive ? '-' : '+'}</div>
        <div className='text-xl md:text-3xl'>Mäklare</div>
      </div>
      <div className={
        `${isFifthActive ? 'h-auto mb-4 md:mb-10 transition-all duration-300 ' :
    'h-0 transition-all overflow-hidden opacity-0 duration-300 '}` +
    'md:flex justify-between w-full'
      }>
        <div className='text-base font-normal pl-7 w-full'>
          {realtors.map((el: any, idx: any) => (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.1 * idx,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} key={idx} className={`pb-4 ${idx === 0 && 'font-bold'}`}>
              <div>
                {(el.type && el.type !== null) && <span>{el.type} </span>}
                {el.name && el.name !== null && <span>{el.name}</span>}
              </div>
              <div>
                {el.email && el.email !== null && <a href={`mailto:${el.email}`}>{el.email}, </a>}
                {el.phone && el.phone !== null && <span>{el.phone}</span>}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccordionMaklarePart;
