/* eslint-disable max-len */
import client from './client';

const getFasadItemShowings = async (id : number) => {
  const URL = `listings/${id}/showings`;
  const token = await client.post(`auth/login?client_secret=${process.env.REACT_APP_FASAD_CLIENT_SECRET}&password=${process.env.REACT_APP_FASAD_PASSWORD}`);
  return await client.get(URL, {
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
    },
  });
};

function fetchShowings(id : any) {
  return getFasadItemShowings(id);
}

export default fetchShowings;
