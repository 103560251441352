import React, {FC} from 'react';
import {Hero} from '../../types';
import HeroFull from './HeroFull';
import HeroSplitRight from './HeroSplitRight';

type HeroSlicesProps = {
  item: Hero;
  isBold?: boolean;
  page?: string;
  isShowings?: any;
  isBiddings?: any;
  scrollToShowings?: any;
}

const HeroSlices: FC<HeroSlicesProps> = ({item, isBold, page, isShowings, isBiddings, scrollToShowings}) => {
  if (!item) {
    return null;
  }

  return (
    <div>
      {item?.variant === 'split-right' && (
        <HeroSplitRight
          details={item}
          isBold={isBold}
          page={page}
          isShowings={isShowings}
          isBiddings={isBiddings}
          scrollToShowings={scrollToShowings}/>)}
      {item?.variant === 'full' && <HeroFull details={item}/>}
    </div>
  );
};

export default HeroSlices;
