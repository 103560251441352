import React, {FC, useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';

type UIContactFormProps = {
  fasadId: any;
  color: string;
  header: string;
  showingsData?: any;
  address: string;
}

const UIContactForm: FC<UIContactFormProps> = ({fasadId, color, header, showingsData, address}) => {
  type Values = {
    fName : string,
    lName : string,
    email : string,
    phone : string,
    message : string,
    visitDay : string,
    visitSlot : string,
  }

  const [values, setValues] = useState<Values>({
    fName: '',
    lName: '',
    email: '',
    phone: '',
    message: '',
    visitDay: '',
    visitSlot: '',
  });
  const [dayPicked, setDayPicked] = useState<string>();
  const [dayIdx, setDayIdx] = useState<number>();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [event.target.name]: event.target.value});
  };

  useEffect(() => {
    if (values.visitDay && values.visitDay?.length > 0) {
      const idx = showingsData.showings.map((el : any) => el.id).indexOf(parseInt(values.visitDay));
      setDayIdx(idx);
    }
  }, [dayPicked, dayIdx]);

  const sendRequest = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const firstName = values.fName;
    const lastName = values.lName;
    const mail = values.email;
    const phone = values.phone;
    const msg = values.message;
    const showing = values.visitDay;
    const slot = values.visitSlot;
    const object = fasadId;
    const type = 4;

    if (isSending) return;
    setIsSending(true);
    const requestOptions = {
      method: 'GET',
      mode: 'no-cors' as RequestMode,
      headers: {'Content-Type': 'application/jsonp'},
    };
    if (slot) {
      fetch(
          // eslint-disable-next-line max-len
          `https://crm.fasad.eu/api/customerqueue/addshowingcustomertoqueue?showing=${showing}&slot=${slot}&fkobject=${object}&firstname=${firstName}&lastname=${lastName}&mail=${mail}&cellphone=${phone}&message=${msg}&policytypeid=${type}`,
          requestOptions,
      ).then((response) => response.json());
      setIsSending(false);
      setIsSent(true);
    }

    if (!slot && !showing) {
      fetch(
          // eslint-disable-next-line max-len
          `https://crm.fasad.eu/api/customerqueue/addinterestcustomertoqueue?fkobject=${object}&firstname=${firstName}&lastname=${lastName}&mail=${mail}&cellphone=${phone}&message=${msg}&policytypeid=${type}`,
          requestOptions,
      ).then((response) => response.json());
      setIsSending(false);
      setIsSent(true);
    }
  };

  return (
    <motion.div
      initial={{opacity: 0, y: 20}}
      whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
      viewport={{once: true}}
      transition={{
        delay: 0.3,
        x: {type: 'easeOut', stiffness: 100},
        default: {duration: 1},
      }} className={`flex flex-col justify-center w-full md:w-4/5 lg:w-4/6 xl:w-7/12 mx-auto ${color}`}>
      <div className='w-full px-8 md:w-2/3 mx-auto pt-10 md:pt-20 lg:pt-28 pb-4 md:pb-10 lg:pb-14 max-w-xl'>
        {!isSent ? (
          <form onSubmit={(e) => sendRequest(e)}>
            {address && <div className='text-15 font-bold mb-2'>{address}</div>}
            <div className={
              `${color === 'bg-beige-contact' ? 'font-bold' : ''}` +
            ' text-35 leading-10 pb-16'}>
              {header}
            </div>
            <div className='text-15'>
              {showingsData && (
                <div className='pb-1'>
                  <div className='pb-2'>Boka visning</div>
                  <select
                    value={values.visitDay}
                    name="visitDay"
                    onChange={(e : any) => {
                      handleChange(e);
                      setDayPicked(values.visitDay);
                    }}
                    className='bg-transparent w-full border-b border-black'>
                    <option disabled={true} value="">Välj visningstid</option>
                    {showingsData.showings.length > 0 && showingsData.showings.map((el : any) => (
                      <option key={el.id} value={el.id}>
                        {el.startDate + '-' + el.endDate}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {values.visitDay && (
                <div className='pb-1'>
                  <div className='pb-2'>Boka slot</div>
                  <select
                    value={values.visitSlot}
                    name="visitSlot"
                    onChange={(e : any) => {
                      handleChange(e);
                    }}
                    className='bg-transparent w-full border-b border-black'>
                    <option disabled={true} value="">Välj visningstid</option>
                    {showingsData.showings.length > 0 &&
                  dayIdx != undefined &&
                  showingsData.showings[dayIdx].Slots.map((el : any) => (
                    <option key={el.id} value={el.id}>
                      {el.startTime + '-' + el.endTime}
                    </option>
                  ))}
                  </select>
                </div>
              )}
              <div className='pb-1'>
                <div className='pb-2'>Förnamn<sup>*</sup></div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'fName'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Efternamn<sup>*</sup></div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'lName'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>E-postadress<sup>*</sup></div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'email'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Mobil</div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'phone'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Meddelande</div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'message'}
                  required
                />
              </div>
            </div>
            <div className='text-15 py-8'>
              <div className='flex items-center'>
                <input type="checkbox" className='mr-3'/>
                <div>Jag vill ha en kostnadsfri värdering av min bostad.</div>
              </div>
              <div className='flex items-center'>
                <input type="checkbox" className='mr-3'/>
                <div>Jag vill ha hjälp med att hitta en ny bostad.</div>
              </div>
            </div>
            <button className='text-15 font-bold text-center w-full' type={'submit'}>Skicka</button>
            <div className='text-11 pt-12 text-center'>Så hanterar vi dina personuppgifter:
              <Link
                to='/kakor'> newnest.se/kakor</Link></div>
          </form>
        ) : (
          <div className={
            `${color === 'bg-beige-contact' ? 'font-bold' : ''}` +
        ' text-35 leading-10 pb-16'}>
            Tack för ditt intresse!<br/>
            Vi hör av oss till dig.
          </div>
        )}

      </div>
    </motion.div>
  );
};

export default UIContactForm;
