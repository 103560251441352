import React, {FC} from 'react';
import {motion} from 'framer-motion';
import InnerHTML from 'dangerously-set-html-content';
import removeInlineStyles from '../../utils/removeInlineStyles';

type AccordionOmradePartProps = {
  setIsFourthActive: any;
  isFourthActive: any;
  districtInformation: any;
}

const AccordionOmradePart: FC<AccordionOmradePartProps> = ({
  setIsFourthActive,
  isFourthActive,
  districtInformation,
}) => {
  return (
    <div className="accordion-item">
      <div className="flex items-center cursor-pointer my-1 md:my-2" onClick={() => setIsFourthActive(!isFourthActive)}>
        <div className='text-xl md:text-3xl mr-4'>{isFourthActive ? '-' : '+'}</div>
        <div className='text-xl md:text-3xl'>Område</div>
      </div>
      <div className={
        `${isFourthActive ? 'h-auto mb-4 md:mb-10 transition-all duration-300 ' :
    'h-0 transition-all overflow-hidden opacity-0 duration-300 '}` +
    'md:flex justify-between w-full'
      }>
        <motion.div
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.2,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className='text-sm font-normal pl-7 w-full accordion-item'>
          <InnerHTML
            html={removeInlineStyles(districtInformation)}/>
        </motion.div>
      </div>
    </div>
  );
};

export default AccordionOmradePart;
