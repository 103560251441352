import React from 'react';
import {motion} from 'framer-motion';
import {accordionMenuApi, homePageApi} from '../api';
import {Feature} from '../types';
import FeatureSlices from '../components/Feature/FeatureSlices';
import HeroSlices from '../components/Hero/HeroSlices';
import HomePageAccordionMenu from '../components/HomePage/HomePageAccordionMenu';
import UIBackToTop from '../components/UICommon/UIBackToTopButton';

const homePageResource = homePageApi.fetchData();
const accordionMenuResource = accordionMenuApi.fetchData();

const HomePage = () => {
  const homePageData = homePageResource.content.read();
  const accordionMenuData = accordionMenuResource.content.read();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <HeroSlices item={homePageData?.attributes?.hero}/>
      <FeatureSlices item={homePageData?.attributes?.featureSingle}/>
      {accordionMenuData && (<HomePageAccordionMenu data={accordionMenuData?.attributes}/>)}
      {homePageData?.attributes?.featureRepeatable?.map((item: Feature) => (
        <FeatureSlices key={item.id} item={item}/>
      ))}
      <motion.div
        onClick={scrollToTop}
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.4,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }}>
        <UIBackToTop />
      </motion.div>
    </div>
  );
};

export default HomePage;
