import React, {FC} from 'react';
import {motion} from 'framer-motion';
import {Hero} from '../../types';
import UISingleMedia from '../UICommon/UISingleMedia';

type HeroFullProps = {
  details: Hero;
}

const HeroFull: FC<HeroFullProps> = ({details}) => {
  return (
    <div className='relative h-screenWithoutNav mb-16'>
      {details.media && <UISingleMedia media={details.media}/>}
      <div className={
        'absolute text-white z-10 bottom-0 h-full flex justify-center items-center ' +
        'left-5 first-line:md:px-20 lg:w-3/4'}>
        <div>
          {details.header && (
            <motion.div
              initial={{opacity: 0, x: -50}}
              whileInView={{opacity: 1, x: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.1,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 0.8},
              }}
              className='pl-5 text-5xl md:text-6xl pb-3 lg:text-7xl font-bold lg:pb-8'>{details.header}</motion.div>
          )}
          {details.description && (
            <motion.div
              initial={{opacity: 0, y: 100}}
              whileInView={{opacity: 1, y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.4,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 0.8},
              }}
              className={
                'lg:w-800 font-bold text-lg md:text-3xl leading-6 pr-10 pl-5 lg:text-40 lg:leading-12 md:font-normal'
              }>
              {details.description}
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroFull;
