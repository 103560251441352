/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import axios from 'axios';

const client = axios.create({baseURL: 'https://newnest.azurewebsites.net/api/'});

const API_KEY = '216466e19ded4fa927ffec05cdc056b115a1fdc50c3022be9381b32d7b27d56e5863dc1e8f224abef7417d8f1334d6622ccfaa8657c4eb6933ba4e82324e9ae92ba7ef494544372a0273a1355d500dbe54b1c4895b40f34f3ce61d5166333db4b68a450b0e5de31ec25494e43e3bb544f3f09797ba6ee911be59f38a15299aea';
const PUSH_KEY = '57ee01122ea5b02c32cf77b4e11469fc295371014ca941d0c4a466def6070819c37af88fa5bbb1238c4f5436c5c3fda9d8d7f119cb0c7d585d1319ca6496a6c9d5fc77d10d7ce39264b9cc6ab854c59e2bf47fc199d25ff8141e61edfb18b967e59b73802edfbf4840c437d221a033fbbd4c8e40bfe20f40bd4b67e805cbfdb8';
client.interceptors.request.use((cfg) => {
  cfg!.headers!.Authorization = `Bearer ${PUSH_KEY}`;
  return cfg;
});

export default client;
